exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-collection-tsx": () => import("./../../../src/pages/collection.tsx" /* webpackChunkName: "component---src-pages-collection-tsx" */),
  "component---src-pages-collections-tsx": () => import("./../../../src/pages/collections.tsx" /* webpackChunkName: "component---src-pages-collections-tsx" */),
  "component---src-pages-flashcards-tsx": () => import("./../../../src/pages/flashcards.tsx" /* webpackChunkName: "component---src-pages-flashcards-tsx" */),
  "component---src-pages-home-tsx": () => import("./../../../src/pages/home.tsx" /* webpackChunkName: "component---src-pages-home-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-learn-tsx": () => import("./../../../src/pages/learn.tsx" /* webpackChunkName: "component---src-pages-learn-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-new-collection-tsx": () => import("./../../../src/pages/new-collection.tsx" /* webpackChunkName: "component---src-pages-new-collection-tsx" */),
  "component---src-pages-new-set-tsx": () => import("./../../../src/pages/new-set.tsx" /* webpackChunkName: "component---src-pages-new-set-tsx" */),
  "component---src-pages-set-tsx": () => import("./../../../src/pages/set.tsx" /* webpackChunkName: "component---src-pages-set-tsx" */),
  "component---src-pages-sets-tsx": () => import("./../../../src/pages/sets.tsx" /* webpackChunkName: "component---src-pages-sets-tsx" */)
}

